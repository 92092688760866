import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "select",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/SelectProject"),
  },
  {
    path: "/welcome",
    name: "welcome",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/WelcomePage"),
  },
  {
    path: "/admin-pnl",
    name: "admin",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AdminVIew"),
    children: [
      {
        path: "/",
        name: "home",
        component: () => import("../views/HomeView"),
      },

      {
        path: "settings",
        name: "settings",
        component: () => import("../views/SettingsView"),
      },
      {
        path: "sandbox",
        name: "sandbox",
        component: () => import("../views/SandboxView"),
      },
    ],
  },
  {
    path: "/admin-login",
    name: "admin-login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AdminLogin"),
  },
  {
    path: "/app",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/MainView"),
    meta: { requiresCode: true },
    children: [
      {
        path: "/",
        name: "create",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/CreateView"),
      },
      {
        path: "/online",
        name: "online",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/OnlineView"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history", // встановлення режиму "history"
  routes,
});

router.beforeEach((to, from, next) => {
  const hasCode = !!localStorage.getItem("currentCode");
  const isAuthenticated = !!localStorage.getItem("adminUserToken");

  if (to.path == "/select") {
    console.log("select");
    next();
    return;
  }

  if (to.matched.some((route) => route.meta.requiresAuth)) {
    if (isAuthenticated) {
      next();
    } else {
      next("/admin-login");
    }
  } else if (to.matched.some((route) => route.meta.requiresCode)) {
    if (hasCode) {
      next();
    } else {
      next("/welcome");
    }
  } else {
    next();
  }
});

export default router;
