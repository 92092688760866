<template>
  <v-app>
    <HeaderCommon />
    <div v-if="currentLang && currentLang.value"><router-view /></div>
    <notifications group="foo" />
    <LoaderGlobal />
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import HeaderCommon from "./components/HeaderCommon.vue";
import LoaderGlobal from "../src/components/LoaderGlobal.vue";
export default {
  name: "App",
  components: {
    HeaderCommon,
    LoaderGlobal,
  },
  data: () => ({
    //
  }),
  created() {
    window.getApp = this;
  },
  computed: {
    ...mapGetters("global", ["currentLang"]),
  },
};
</script>
