<template>
  <div class="loader-wrp" v-if="isVisible">
    <div class="loader">
      <img src="../images/logo-xs.svg" />
      <div class="loader-text mt-6" v-if="text">{{ text }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      isVisible: false,
      text: "Loading...",
    };
  },
  created() {
    window.getApp.$on("LOADER_VISIBILITY", (show, text) => {
      this.isVisible = show;
      this.text = text ? text : "";
      document.documentElement.style.overflow = show ? "hidden" : "auto";
    });
  },
};
</script>

<style lang="scss">
.loader-wrp {
  width: 100%;
  min-height: 100vh;
  height: auto;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  .loader {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  img {
    animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
    width: 50px;
  }
}
.loader-text {
  font-size: 18px;
  font-family: "PT sans", sans-serif !important;
  font-weight: 600;
}
@keyframes lds-heart {
  0% {
    transform: scale(0.95);
  }
  5% {
    transform: scale(1.1);
  }
  39% {
    transform: scale(0.85);
  }
  45% {
    transform: scale(1);
  }
  60% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.9);
  }
}
</style>
