import API from "./index";

export const checkVerifyCode = (id) => {
  return API.post(`/codes/${id}/verify`);
};
export const changeArt = (id, code, params) => {
  return API.put(`/codes/${code}/arts/${id}`, params);
};

export const selectedArt = (id, code) => {
  return API.put(`/codes/${code}/arts/${id}/select`);
};

export const getVerifiedCodeInfo = (id) => {
  return API.get(`/codes/${id}/verified`);
};

export const getPdf = (code, id) => {
  return API.get(`/codes/${code}/arts/${id}/pdf`, {
    headers: {
      Accept: "application/pdf",
      "Content-Type": "application/pdf",
    },
  });
};

export const sendEmailPdf = (id, code, data, lang = "en") => {
  return API.post(`/codes/${code}/arts/${id}/mail`, data, {
    headers: { "x-lang": lang },
  });
};

export const drawImages = (code, id) => {
  // /api/codes/4a8f-4a63/arts/11/draw
  return API.get(`/codes/${code}/arts/${id}/draw`);
};

export const handleDraw = (code, id) => {
  const token = localStorage.getItem("userToken");
  return fetch(`${process.env.VUE_APP_API_URL}/codes/${code}/arts/${id}/draw`, {
    headers: {
      "X-CODE-TOKEN": token,
    },
  });
};

export const postImage = (params) => {
  let bodyFormData = new FormData();

  console.log(params);

  bodyFormData.append("file", params.file);
  // bodyFormData.append("params", JSON.stringify(params.params));

  bodyFormData.append("params[sigma]", +params.params.sigma);
  bodyFormData.append("params[pegsCount]", +params.params.pegsCount);
  bodyFormData.append(
    "params[linesCount]",
    +JSON.stringify(params.params.linesCount)
  );

  return API.post(`codes/${params.code}/process`, bodyFormData, {
    headers: {
      "X-Requested-With": "XMLHttpRequest",
    },
  });
};
