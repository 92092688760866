import { getVerifiedCodeInfo } from "../../api/api";
export default {
  namespaced: true,
  state: {
    currentLang: null,
    currentCode: null,
    testCode: {
      id: 1,
      codeValue: "caff-4e55",
      attempts: 2,
      isSelected: true,
      expiredAt: null,
      createdAt: "2023-05-05T21:34:39.688Z",
      updatedAt: "2023-05-05T21:34:39.688Z",
      deletedAt: null,
      arts: [
        {
          id: 103,
          currentStep: 1488,
          isFinished: false,
          segments: [
            "51",
            "76",
            "45",
            "71",
            "44",
            "69",
            "105",
            "134",
            "209",
            "159",
            "126",
            "181",
            "157",
            "213",
            "144",
            "111",
            "7",
            "33",
            "100",
            "19",
            "101",
            "28",
            "104",
            "27",
            "93",
            "32",
            "98",
            "29",
            "94",
            "36",
            "103",
            "23",
            "95",
            "35",
            "86",
            "41",
            "97",
            "34",
            "92",
            "39",
            "146",
            "212",
            "136",
            "214",
            "141",
            "112",
            "9",
            "114",
            "160",
            "195",
            "129",
            "191",
            "167",
            "205",
            "181",
            "145",
            "108",
            "132",
            "187",
            "144",
            "206",
            "165",
            "209",
            "137",
            "207",
            "151",
            "220",
            "158",
            "211",
            "133",
            "106",
            "149",
            "221",
            "196",
            "128",
            "192",
            "166",
            "226",
            "194",
            "162",
            "228",
            "114",
            "239",
            "112",
            "10",
            "113",
            "140",
            "216",
            "156",
            "229",
            "183",
            "144",
            "197",
            "159",
            "206",
            "138",
            "184",
            "227",
            "163",
            "193",
            "127",
            "198",
            "142",
            "213",
            "133",
            "31",
            "99",
            "24",
            "102",
            "21",
            "95",
            "6",
            "34",
            "126",
            "217",
            "139",
            "222",
            "130",
            "226",
            "199",
            "141",
            "208",
            "150",
            "179",
            "146",
            "107",
            "164",
            "57",
            "82",
            "50",
            "77",
            "45",
            "74",
            "48",
            "80",
            "53",
            "161",
            "229",
            "133",
            "214",
            "131",
            "188",
            "143",
            "207",
            "171",
            "147",
            "231",
            "115",
            "11",
            "116",
            "12",
            "112",
            "227",
            "193",
            "165",
            "56",
            "83",
            "107",
            "142",
            "226",
            "202",
            "152",
            "177",
            "206",
            "126",
            "31",
            "103",
            "32",
            "89",
            "34",
            "96",
            "20",
            "100",
            "29",
            "122",
            "19",
            "117",
            "199",
            "138",
            "223",
            "131",
            "190",
            "143",
            "221",
            "132",
            "229",
            "155",
            "198",
            "158",
            "210",
            "149",
            "57",
            "151",
            "178",
            "205",
            "160",
            "54",
            "79",
            "49",
            "156",
            "218",
            "115",
            "17",
            "121",
            "21",
            "100",
            "30",
            "91",
            "14",
            "86",
            "40",
            "88",
            "38",
            "93",
            "37",
            "87",
            "42",
            "85",
            "6",
            "92",
            "33",
            "99",
            "15",
            "118",
            "225",
            "143",
            "212",
            "134",
            "208",
            "170",
            "226",
            "133",
            "32",
            "125",
            "27",
            "101",
            "24",
            "94",
            "35",
            "90",
            "9",
            "96",
            "12",
            "117",
            "0",
            "115",
            "202",
            "127",
            "102",
            "22",
            "120",
            "1",
            "56",
            "159",
            "229",
            "128",
            "227",
            "111",
            "210",
            "147",
            "223",
            "172",
            "197",
            "161",
            "112",
            "8",
            "32",
            "124",
            "21",
            "116",
            "231",
            "129",
            "224",
            "137",
            "200",
            "153",
            "220",
            "133",
            "230",
            "113",
            "228",
            "136",
            "225",
            "171",
            "198",
            "143",
            "216",
            "165",
            "219",
            "155",
            "61",
            "169",
            "227",
            "141",
            "81",
            "51",
            "82",
            "52",
            "5",
            "97",
            "25",
            "88",
            "41",
            "142",
            "221",
            "148",
            "211",
            "111",
            "87",
            "38",
            "84",
            "32",
            "96",
            "26",
            "103",
            "135",
            "228",
            "139",
            "114",
            "220",
            "174",
            "206",
            "152",
            "231",
            "131",
            "27",
            "127",
            "30",
            "134",
            "186",
            "145",
            "182",
            "149",
            "209",
            "121",
            "20",
            "101",
            "23",
            "117",
            "224",
            "146",
            "196",
            "108",
            "141",
            "222",
            "195",
            "153",
            "47",
            "76",
            "7",
            "91",
            "0",
            "33",
            "94",
            "5",
            "100",
            "32",
            "95",
            "31",
            "136",
            "231",
            "132",
            "213",
            "114",
            "235",
            "118",
            "201",
            "134",
            "184",
            "218",
            "137",
            "211",
            "144",
            "40",
            "85",
            "15",
            "113",
            "239",
            "125",
            "237",
            "123",
            "30",
            "97",
            "11",
            "117",
            "20",
            "105",
            "151",
            "203",
            "149",
            "180",
            "220",
            "164",
            "188",
            "120",
            "224",
            "145",
            "205",
            "127",
            "14",
            "115",
            "194",
            "119",
            "223",
            "148",
            "231",
            "134",
            "228",
            "138",
            "86",
            "43",
            "150",
            "195",
            "225",
            "140",
            "227",
            "162",
            "196",
            "147",
            "50",
            "75",
            "46",
            "79",
            "53",
            "12",
            "88",
            "9",
            "72",
            "5",
            "98",
            "25",
            "100",
            "41",
            "105",
            "155",
            "193",
            "121",
            "2",
            "119",
            "10",
            "87",
            "36",
            "90",
            "235",
            "89",
            "35",
            "93",
            "6",
            "110",
            "230",
            "127",
            "216",
            "177",
            "223",
            "164",
            "32",
            "122",
            "236",
            "120",
            "206",
            "109",
            "14",
            "72",
            "47",
            "139",
            "227",
            "137",
            "231",
            "154",
            "199",
            "121",
            "34",
            "99",
            "28",
            "124",
            "19",
            "233",
            "29",
            "96",
            "37",
            "136",
            "183",
            "219",
            "175",
            "151",
            "223",
            "141",
            "216",
            "187",
            "133",
            "25",
            "103",
            "5",
            "30",
            "95",
            "15",
            "127",
            "227",
            "110",
            "203",
            "146",
            "84",
            "17",
            "94",
            "34",
            "169",
            "209",
            "140",
            "224",
            "163",
            "189",
            "128",
            "215",
            "188",
            "129",
            "213",
            "143",
            "226",
            "171",
            "64",
            "159",
            "219",
            "126",
            "222",
            "173",
            "221",
            "155",
            "51",
            "6",
            "96",
            "14",
            "113",
            "11",
            "118",
            "1",
            "136",
            "44",
            "88",
            "39",
            "91",
            "8",
            "114",
            "7",
            "63",
            "161",
            "231",
            "138",
            "34",
            "5",
            "106",
            "147",
            "185",
            "217",
            "116",
            "224",
            "126",
            "227",
            "122",
            "20",
            "76",
            "108",
            "230",
            "112",
            "13",
            "125",
            "3",
            "97",
            "6",
            "101",
            "22",
            "105",
            "189",
            "213",
            "165",
            "65",
            "89",
            "24",
            "59",
            "167",
            "223",
            "154",
            "229",
            "130",
            "201",
            "152",
            "48",
            "81",
            "52",
            "146",
            "204",
            "144",
            "226",
            "148",
            "184",
            "155",
            "182",
            "220",
            "150",
            "58",
            "15",
            "90",
            "33",
            "124",
            "235",
            "123",
            "230",
            "109",
            "73",
            "46",
            "153",
            "20",
            "125",
            "207",
            "108",
            "209",
            "129",
            "25",
            "118",
            "0",
            "132",
            "222",
            "179",
            "39",
            "86",
            "42",
            "84",
            "238",
            "60",
            "14",
            "102",
            "5",
            "67",
            "12",
            "113",
            "8",
            "90",
            "31",
            "104",
            "30",
            "141",
            "206",
            "134",
            "227",
            "156",
            "182",
            "219",
            "135",
            "185",
            "144",
            "89",
            "181",
            "110",
            "202",
            "178",
            "40",
            "105",
            "6",
            "64",
            "238",
            "82",
            "49",
            "198",
            "145",
            "225",
            "131",
            "232",
            "136",
            "0",
            "114",
            "200",
            "157",
            "215",
            "142",
            "231",
            "119",
            "237",
            "124",
            "100",
            "26",
            "233",
            "23",
            "60",
            "7",
            "94",
            "16",
            "110",
            "212",
            "168",
            "210",
            "118",
            "236",
            "121",
            "217",
            "138",
            "229",
            "129",
            "90",
            "37",
            "103",
            "24",
            "106",
            "67",
            "6",
            "77",
            "14",
            "51",
            "142",
            "204",
            "134",
            "199",
            "169",
            "111",
            "239",
            "115",
            "228",
            "133",
            "92",
            "38",
            "83",
            "7",
            "96",
            "27",
            "88",
            "217",
            "164",
            "194",
            "154",
            "202",
            "128",
            "231",
            "126",
            "3",
            "93",
            "31",
            "140",
            "215",
            "84",
            "163",
            "225",
            "157",
            "17",
            "57",
            "152",
            "229",
            "143",
            "214",
            "188",
            "137",
            "42",
            "94",
            "37",
            "127",
            "218",
            "136",
            "85",
            "43",
            "90",
            "12",
            "118",
            "23",
            "119",
            "35",
            "171",
            "25",
            "138",
            "226",
            "162",
            "191",
            "128",
            "210",
            "145",
            "212",
            "166",
            "34",
            "102",
            "13",
            "109",
            "5",
            "86",
            "234",
            "111",
            "8",
            "122",
            "230",
            "132",
            "32",
            "92",
            "29",
            "134",
            "221",
            "163",
            "61",
            "11",
            "119",
            "202",
            "168",
            "192",
            "142",
            "1",
            "135",
            "226",
            "123",
            "222",
            "172",
            "146",
            "197",
            "149",
            "196",
            "152",
            "223",
            "157",
            "191",
            "99",
            "27",
            "117",
            "13",
            "86",
            "112",
            "228",
            "106",
            "6",
            "71",
            "238",
            "69",
            "17",
            "100",
            "65",
            "20",
            "51",
            "156",
            "231",
            "114",
            "211",
            "127",
            "220",
            "175",
            "209",
            "150",
            "225",
            "108",
            "18",
            "93",
            "1",
            "97",
            "24",
            "56",
            "81",
            "55",
            "83",
            "35",
            "133",
            "215",
            "139",
            "210",
            "173",
            "94",
            "236",
            "125",
            "11",
            "102",
            "4",
            "128",
            "198",
            "146",
            "221",
            "115",
            "230",
            "130",
            "19",
            "86",
            "34",
            "143",
            "231",
            "145",
            "226",
            "149",
            "195",
            "134",
            "176",
            "208",
            "142",
            "14",
            "95",
            "28",
            "96",
            "43",
            "68",
            "1",
            "117",
            "31",
            "238",
            "110",
            "235",
            "91",
            "36",
            "128",
            "0",
            "131",
            "227",
            "113",
            "214",
            "164",
            "213",
            "82",
            "54",
            "202",
            "147",
            "186",
            "218",
            "118",
            "18",
            "99",
            "5",
            "107",
            "19",
            "162",
            "225",
            "139",
            "231",
            "117",
            "24",
            "238",
            "79",
            "48",
            "73",
            "2",
            "143",
            "200",
            "120",
            "9",
            "70",
            "15",
            "51",
            "105",
            "21",
            "75",
            "8",
            "132",
            "224",
            "172",
            "196",
            "93",
            "127",
            "229",
            "146",
            "209",
            "118",
            "222",
            "114",
            "13",
            "126",
            "230",
            "107",
            "16",
            "84",
            "40",
            "97",
            "227",
            "161",
            "12",
            "95",
            "5",
            "92",
            "35",
            "85",
            "141",
            "213",
            "123",
            "18",
            "67",
            "202",
            "129",
            "226",
            "157",
            "182",
            "150",
            "75",
            "238",
            "56",
            "20",
            "63",
            "1",
            "54",
            "25",
            "122",
            "235",
            "115",
            "34",
            "162",
            "218",
            "133",
            "232",
            "30",
            "94",
            "225",
            "170",
            "68",
            "106",
            "131",
            "200",
            "145",
            "237",
            "126",
            "4",
            "100",
            "27",
            "103",
            "228",
            "111",
            "10",
            "121",
            "33",
            "96",
            "185",
            "209",
            "166",
            "208",
            "151",
            "193",
            "134",
            "210",
            "144",
            "203",
            "127",
            "215",
            "112",
            "218",
            "143",
            "31",
            "98",
            "22",
            "123",
            "217",
            "137",
            "175",
            "223",
            "162",
            "32",
            "131",
            "236",
            "71",
            "105",
            "13",
            "87",
            "39",
            "85",
            "234",
            "107",
            "216",
            "139",
            "226",
            "117",
            "37",
            "99",
            "6",
            "108",
            "11",
            "140",
            "214",
            "128",
            "228",
            "102",
            "26",
            "104",
            "17",
            "76",
            "201",
            "169",
            "86",
            "15",
            "110",
            "239",
            "124",
            "215",
            "163",
            "134",
            "190",
            "158",
            "8",
            "73",
            "32",
            "64",
            "9",
            "61",
            "21",
            "50",
            "143",
            "10",
            "123",
            "14",
            "84",
            "37",
            "174",
            "198",
            "148",
            "227",
            "136",
            "162",
            "111",
            "235",
            "117",
            "93",
            "34",
            "100",
            "155",
            "192",
            "156",
            "23",
            "122",
            "3",
            "140",
            "204",
            "109",
            "226",
            "150",
            "49",
            "193",
            "92",
            "7",
            "115",
            "26",
            "120",
            "216",
            "123",
            "206",
            "66",
            "19",
            "177",
            "207",
            "60",
            "85",
            "20",
            "171",
            "134",
            "219",
            "118",
            "33",
            "103",
            "16",
            "143",
            "205",
            "153",
            "184",
            "100",
            "222",
            "98",
            "13",
            "88",
            "239",
            "127",
            "231",
            "141",
            "211",
            "165",
            "220",
            "117",
            "225",
            "155",
            "217",
            "163",
            "66",
            "8",
            "89",
            "41",
            "85",
            "44",
            "75",
            "203",
            "167",
            "58",
            "16",
            "122",
            "223",
            "129",
            "185",
            "219",
            "131",
            "206",
            "108",
            "5",
            "104",
            "224",
            "118",
            "30",
            "99",
            "45",
            "78",
            "53",
            "83",
            "215",
            "150",
            "231",
            "113",
            "17",
            "79",
            "2",
            "124",
            "12",
            "101",
            "18",
            "172",
            "11",
            "174",
            "70",
            "155",
            "128",
            "195",
            "119",
            "0",
            "138",
            "27",
            "97",
            "36",
            "92",
            "31",
            "137",
            "161",
            "15",
            "107",
            "9",
            "125",
            "221",
            "162",
            "190",
            "166",
            "204",
            "123",
            "177",
            "129",
            "220",
            "139",
            "25",
            "106",
            "234",
            "112",
            "222",
            "178",
            "72",
            "19",
            "98",
            "2",
            "145",
            "213",
            "151",
            "225",
            "159",
            "51",
            "233",
            "86",
            "37",
            "88",
            "31",
            "122",
            "229",
            "108",
            "227",
            "143",
            "82",
            "8",
            "131",
            "215",
            "187",
            "140",
            "201",
            "111",
            "13",
            "118",
            "217",
            "162",
            "105",
            "43",
            "87",
            "5",
            "78",
            "48",
            "7",
            "136",
            "2",
            "101",
            "14",
            "59",
            "32",
            "116",
            "10",
            "114",
            "210",
            "164",
            "212",
            "133",
            "222",
            "160",
            "18",
            "74",
            "238",
            "109",
            "140",
            "221",
            "127",
            "226",
            "110",
            "234",
            "105",
            "39",
            "148",
            "200",
            "171",
            "66",
            "211",
            "146",
            "231",
            "157",
            "25",
            "55",
            "22",
            "100",
            "35",
            "138",
            "232",
            "145",
            "6",
            "103",
            "72",
            "44",
            "135",
            "201",
            "128",
            "26",
            "97",
            "61",
            "13",
            "122",
            "191",
            "152",
            "79",
            "47",
            "81",
            "238",
            "68",
            "156",
            "237",
            "143",
            "219",
            "161",
            "198",
            "118",
            "204",
            "67",
            "2",
            "125",
            "229",
            "131",
            "1",
            "138",
            "28",
            "94",
            "21",
            "62",
            "11",
            "106",
            "19",
            "92",
            "236",
            "116",
            "7",
            "120",
            "230",
            "114",
            "199",
            "144",
            "216",
            "186",
            "162",
            "214",
            "107",
            "238",
            "63",
            "206",
            "151",
            "194",
            "130",
            "6",
            "86",
            "29",
            "93",
            "135",
            "218",
            "176",
            "28",
            "53",
            "142",
            "85",
            "32",
            "90",
            "5",
            "105",
            "12",
            "154",
            "226",
            "158",
            "182",
            "27",
            "65",
            "8",
            "95",
            "221",
            "120",
            "3",
            "92",
            "235",
            "119",
            "207",
            "146",
            "187",
            "153",
            "74",
            "49",
            "22",
            "107",
            "18",
            "109",
            "203",
            "134",
            "189",
            "25",
            "140",
            "87",
            "14",
            "71",
            "106",
            "218",
            "110",
            "199",
            "137",
            "213",
            "127",
            "206",
            "180",
            "237",
            "133",
            "227",
            "147",
            "228",
            "132",
            "21",
            "152",
            "193",
            "69",
            "236",
            "111",
            "51",
            "187",
            "156",
            "60",
            "29",
            "102",
            "126",
            "41",
            "96",
            "2",
            "138",
            "36",
            "98",
            "238",
            "66",
            "91",
            "38",
            "82",
            "40",
            "143",
            "201",
            "68",
            "17",
            "120",
            "227",
            "104",
            "14",
            "94",
            "27",
            "170",
            "224",
            "159",
            "237",
            "146",
            "175",
            "111",
            "230",
            "129",
            "7",
            "67",
            "210",
            "127",
            "12",
            "102",
            "32",
            "163",
            "212",
            "140",
            "183",
            "24",
            "126",
            "215",
            "123",
            "223",
            "115",
            "33",
            "89",
            "239",
            "109",
            "235",
            "161",
            "221",
            "181",
            "100",
            "16",
            "85",
            "21",
            "99",
            "23",
            "106",
            "7",
            "155",
            "201",
            "148",
            "195",
            "118",
            "20",
            "157",
            "228",
            "101",
            "34",
            "147",
            "13",
            "127",
            "3",
            "142",
            "227",
            "138",
            "43",
            "94",
            "9",
            "123",
            "212",
            "188",
            "135",
            "214",
            "145",
            "87",
            "45",
            "88",
            "219",
            "109",
            "205",
            "128",
            "239",
            "133",
            "204",
            "129",
            "172",
            "211",
            "174",
            "209",
            "176",
            "110",
            "233",
            "84",
            "30",
            "125",
            "227",
            "106",
            "12",
            "120",
            "36",
            "99",
            "59",
            "21",
            "154",
            "2",
            "62",
            "8",
            "102",
            "3",
            "123",
            "221",
            "150",
            "196",
            "115",
            "224",
            "113",
            "213",
            "148",
            "74",
            "5",
            "79",
            "35",
            "122",
            "215",
            "111",
            "217",
            "129",
            "198",
            "96",
            "227",
            "160",
            "200",
            "173",
            "149",
            "212",
            "157",
            "237",
            "141",
            "229",
            "116",
            "222",
            "143",
            "52",
            "80",
            "238",
            "108",
            "12",
            "60",
            "19",
            "64",
            "132",
            "95",
            "2",
            "102",
            "25",
            "58",
            "30",
            "92",
            "17",
            "65",
            "107",
            "224",
            "86",
            "213",
            "155",
            "191",
            "131",
            "216",
            "118",
            "36",
            "104",
            "234",
            "22",
            "115",
            "8",
            "136",
            "202",
            "141",
            "4",
            "129",
            "219",
            "151",
            "24",
            "85",
            "7",
            "74",
            "99",
            "39",
            "161",
            "71",
            "11",
            "134",
            "226",
            "111",
            "9",
            "101",
            "15",
            "66",
            "213",
            "171",
            "237",
            "148",
            "6",
            "118",
            "191",
            "143",
            "208",
            "177",
            "146",
            "1",
            "140",
            "12",
            "92",
            "41",
            "170",
            "222",
            "128",
            "21",
            "55",
            "112",
            "231",
            "149",
            "225",
            "175",
            "69",
            "14",
            "81",
            "32",
            "127",
            "23",
            "90",
            "42",
            "83",
            "238",
            "77",
            "48",
            "157",
            "186",
            "123",
            "208",
            "183",
            "129",
            "212",
            "155",
            "78",
            "136",
            "89",
            "13",
            "100",
            "18",
            "120",
            "215",
            "118",
            "221",
            "172",
            "55",
            "164",
            "209",
            "182",
            "67",
            "14",
            "139",
            "27",
            "54",
            "238",
            "101",
            "128",
            "226",
            "167",
            "211",
            "151",
            "229",
            "105",
            "228",
            "97",
            "16",
            "79",
            "33",
            "127",
            "28",
            "103",
            "227",
            "109",
            "215",
            "145",
            "233",
            "83",
            "31",
            "100",
            "73",
            "239",
            "68",
            "168",
            "10",
            "144",
            "189",
            "118",
            "26",
            "98",
            "7",
            "101",
            "5",
            "88",
            "235",
            "125",
            "214",
            "139",
            "193",
            "154",
            "129",
            "208",
            "107",
            "6",
            "112",
            "0",
            "92",
            "223",
            "116",
            "237",
            "169",
            "135",
            "229",
            "124",
            "14",
            "56",
            "17",
            "50",
            "191",
            "105",
            "218",
            "91",
            "31",
            "134",
            "198",
            "151",
            "231",
            "175",
            "36",
            "132",
            "205",
            "133",
            "60",
            "166",
            "207",
            "131",
            "95",
            "26",
            "111",
            "222",
            "122",
            "11",
            "74",
            "147",
            "174",
            "226",
            "70",
            "45",
            "103",
            "230",
            "76",
            "49",
            "233",
            "142",
            "210",
            "116",
            "204",
            "180",
            "146",
            "213",
            "112",
            "64",
            "18",
            "86",
            "9",
            "82",
            "215",
            "107",
            "228",
            "159",
            "77",
            "10",
            "124",
            "185",
            "30",
            "138",
            "3",
            "157",
            "11",
            "128",
            "212",
            "162",
            "134",
            "181",
            "28",
            "117",
            "38",
            "90",
            "218",
            "193",
            "129",
            "175",
            "135",
            "237",
            "161",
            "224",
            "85",
            "188",
            "27",
            "62",
            "32",
            "121",
            "233",
            "63",
            "88",
            "229",
            "104",
            "7",
            "137",
            "221",
            "176",
            "200",
            "149",
            "6",
            "87",
            "28",
            "92",
            "215",
            "106",
            "20",
            "95",
            "126",
            "223",
            "139",
            "197",
            "64",
            "101",
            "13",
            "115",
            "16",
            "65",
            "14",
            "99",
            "238",
            "102",
            "224",
            "121",
            "1",
            "132",
            "214",
            "127",
            "41",
            "98",
            "15",
            "80",
            "47",
            "22",
            "92",
            "2",
            "150",
            "236",
            "142",
            "201",
            "168",
            "51",
            "83",
            "133",
            "9",
            "100",
            "37",
            "91",
            "3",
            "137",
            "202",
            "148",
            "7",
            "70",
            "115",
            "204",
            "108",
            "235",
            "120",
            "194",
            "152",
            "176",
            "211",
            "63",
            "24",
            "72",
            "159",
            "223",
            "105",
            "215",
            "102",
            "234",
            "143",
            "220",
            "184",
            "160",
            "221",
            "128",
            "209",
            "68",
            "195",
            "127",
            "228",
            "155",
            "179",
            "207",
            "107",
            "213",
            "140",
            "237",
            "173",
            "45",
            "126",
            "15",
            "121",
            "12",
            "70",
            "16",
            "117",
            "75",
            "35",
            "233",
            "109",
            "225",
            "158",
            "231",
            "124",
            "31",
            "180",
            "134",
            "202",
            "111",
            "205",
            "147",
            "51",
            "26",
            "108",
            "17",
            "78",
            "6",
            "128",
            "185",
            "120",
            "13",
            "136",
            "23",
            "64",
            "21",
            "101",
            "29",
            "59",
            "157",
            "192",
            "98",
            "1",
            "95",
            "236",
            "119",
            "16",
            "87",
            "8",
            "145",
            "188",
            "152",
            "209",
            "131",
            "221",
            "117",
            "39",
            "83",
            "14",
            "93",
            "36",
            "105",
            "4",
            "127",
            "223",
            "142",
            "237",
            "79",
            "25",
            "120",
            "6",
            "150",
            "229",
            "140",
            "219",
            "176",
            "224",
            "65",
            "2",
            "139",
            "205",
            "164",
            "222",
            "94",
            "13",
            "103",
            "63",
            "204",
            "102",
            "73",
            "151",
            "226",
            "122",
            "68",
            "19",
            "114",
            "212",
            "113",
            "0",
            "134",
            "170",
            "221",
            "165",
            "12",
            "143",
            "193",
            "141",
            "16",
            "123",
            "203",
            "119",
            "26",
            "133",
            "10",
            "127",
            "58",
            "33",
            "98",
            "74",
            "239",
            "140",
            "233",
            "107",
            "71",
            "162",
            "62",
            "28",
            "97",
            "238",
            "65",
            "213",
            "159",
            "185",
            "146",
            "223",
            "102",
            "227",
            "99",
            "47",
            "136",
            "226",
            "108",
            "199",
            "124",
            "68",
            "155",
            "186",
            "52",
            "77",
            "19",
            "96",
            "11",
            "123",
            "214",
            "154",
            "235",
            "130",
            "236",
            "93",
            "22",
            "85",
            "38",
            "81",
            "9",
            "131",
            "193",
            "164",
            "208",
            "118",
            "231",
            "169",
            "10",
            "97",
            "4",
            "107",
            "227",
            "95",
            "36",
            "86",
            "228",
            "126",
            "58",
            "216",
            "145",
            "110",
            "225",
            "152",
            "221",
            "113",
            "139",
            "0",
            "88",
            "3",
            "124",
            "195",
            "112",
            "224",
            "158",
            "209",
            "177",
            "218",
            "153",
            "69",
            "163",
            "210",
            "129",
            "181",
            "141",
            "25",
            "61",
            "33",
            "87",
            "17",
            "64",
            "172",
            "38",
            "136",
            "223",
            "121",
            "230",
            "100",
            "23",
            "105",
            "7",
            "31",
            "116",
            "0",
            "111",
            "3",
            "90",
            "146",
            "226",
            "140",
            "203",
            "166",
            "11",
            "85",
            "8",
            "125",
            "220",
            "173",
            "212",
            "127",
            "189",
            "114",
            "18",
            "78",
            "49",
            "21",
            "74",
            "12",
            "104",
            "72",
            "10",
            "100",
            "234",
            "138",
            "235",
            "145",
            "218",
            "194",
            "128",
            "219",
            "152",
            "2",
            "94",
            "40",
            "14",
            "79",
            "45",
            "91",
            "67",
            "20",
            "62",
            "22",
            "109",
            "6",
            "134",
            "161",
            "222",
            "110",
            "221",
            "139",
            "80",
            "215",
            "178",
            "208",
            "155",
            "15",
            "88",
            "7",
            "100",
            "228",
            "104",
            "226",
            "119",
            "17",
            "83",
            "145",
            "13",
            "131",
            "194",
            "137",
            "1",
            "129",
            "207",
            "183",
            "128",
            "30",
            "106",
            "58",
            "233",
            "150",
            "78",
            "38",
            "115",
            "91",
            "32",
            "99",
            "16",
            "127",
            "0",
            "188",
            "132",
            "190",
            "144",
            "237",
            "176",
            "12",
            "122",
            "219",
            "173",
            "37",
            "118",
            "28",
            "60",
            "164",
            "14",
            "92",
            "65",
            "194",
            "30",
            "142",
            "15",
            "120",
            "21",
            "111",
            "213",
            "147",
            "221",
            "124",
            "200",
            "168",
            "140",
            "54",
            "215",
            "75",
            "236",
            "117",
            "17",
            "70",
            "109",
            "222",
            "104",
            "163",
            "220",
            "121",
            "229",
            "71",
            "148",
            "209",
            "114",
            "63",
            "18",
            "55",
            "185",
            "112",
            "143",
            "232",
            "81",
            "5",
            "89",
            "42",
            "67",
            "170",
            "198",
            "110",
            "214",
            "146",
            "199",
            "75",
            "17",
            "96",
            "62",
            "24",
            "113",
            "235",
            "132",
            "207",
            "158",
            "186",
            "149",
            "184",
            "90",
            "230",
            "98",
            "237",
            "154",
            "61",
            "238",
            "106",
            "13",
            "148",
            "232",
            "27",
            "107",
            "223",
            "83",
            "34",
            "109",
            "12",
            "162",
            "211",
            "123",
            "8",
            "96",
            "39",
            "171",
            "231",
            "153",
            "212",
            "112",
            "2",
            "100",
            "14",
            "131",
            "6",
            "151",
            "48",
            "169",
            "193",
            "220",
            "141",
            "0",
            "95",
            "234",
            "147",
            "76",
            "15",
            "91",
            "34",
            "106",
            "224",
            "101",
            "227",
            "157",
            "202",
            "123",
            "197",
            "116",
            "29",
            "54",
            "80",
            "9",
            "121",
            "215",
            "144",
            "62",
            "120",
            "222",
            "102",
            "209",
            "168",
            "49",
            "19",
            "69",
            "226",
            "175",
            "134",
            "169",
            "129",
            "216",
            "113",
            "223",
            "117",
            "21",
            "66",
            "235",
            "82",
            "136",
            "3",
            "87",
            "44",
            "104",
            "217",
            "153",
            "193",
            "146",
            "6",
            "73",
            "16",
            "120",
            "202",
            "130",
            "7",
            "121",
            "212",
            "148",
            "203",
            "79",
            "234",
            "157",
            "13",
            "95",
            "228",
            "98",
            "28",
            "93",
            "41",
            "0",
            "133",
            "221",
            "138",
            "215",
            "136",
            "90",
            "17",
            "67",
            "25",
            "108",
            "217",
            "142",
            "167",
            "202",
            "159",
            "222",
            "127",
            "5",
            "96",
            "233",
            "102",
            "166",
            "237",
            "137",
            "229",
            "91",
            "51",
            "117",
            "9",
            "78",
            "46",
            "71",
            "199",
            "168",
            "195",
            "101",
            "1",
            "125",
            "209",
            "154",
            "187",
            "86",
            "45",
            "21",
            "109",
            "41",
            "133",
            "2",
            "123",
            "20",
            "131",
            "196",
            "140",
            "27",
            "119",
            "19",
            "53",
            "229",
            "134",
            "197",
            "127",
            "35",
            "120",
            "96",
            "24",
            "81",
            "31",
            "57",
            "204",
            "107",
            "226",
            "163",
            "4",
            "106",
            "36",
            "176",
            "128",
            "208",
            "179",
            "149",
            "194",
            "143",
            "239",
            "153",
            "214",
            "64",
            "203",
            "94",
            "6",
            "100",
            "227",
            "146",
            "18",
            "70",
            "193",
            "231",
            "159",
            "67",
            "136",
            "233",
            "123",
            "210",
            "113",
            "190",
            "164",
            "207",
            "133",
            "16",
            "88",
            "234",
            "56",
            "9",
            "93",
            "30",
            "59",
            "238",
            "111",
            "32",
            "87",
            "20",
            "115",
            "10",
            "78",
            "156",
            "2",
            "120",
            "237",
            "147",
            "211",
            "155",
            "227",
            "145",
            "228",
            "142",
            "63",
            "94",
            "229",
            "123",
            "1",
            "51",
            "216",
            "174",
            "57",
            "8",
            "84",
            "33",
            "154",
            "106",
            "79",
            "149",
            "37",
            "92",
            "132",
            "6",
            "88",
            "148",
            "222",
            "163",
            "196",
            "220",
            "177",
            "211",
            "128",
            "96",
            "223",
            "125",
            "228",
            "64",
            "28",
            "131",
            "17",
            "49",
            "104",
            "213",
            "110",
            "11",
            "126",
            "18",
            "115",
            "233",
            "67",
            "168",
            "221",
            "129",
            "203",
            "164",
            "66",
            "27",
            "109",
            "224",
            "100",
            "45",
            "234",
            "140",
            "220",
            "170",
            "194",
            "47",
            "152",
            "222",
            "90",
            "239",
            "134",
            "69",
            "9",
            "99",
            "38",
            "80",
            "1",
            "115",
            "12",
            "78",
            "51",
            "171",
            "5",
            "93",
            "17",
            "82",
            "186",
            "118",
            "2",
            "97",
            "226",
            "160",
            "212",
            "107",
            "13",
            "73",
            "161",
            "200",
            "164",
            "21",
            "48",
            "125",
            "0",
            "137",
            "205",
            "179",
            "134",
            "158",
            "223",
            "133",
            "3",
            "117",
            "10",
            "88",
            "18",
            "151",
            "232",
            "121",
            "207",
            "155",
            "45",
            "195",
            "141",
            "201",
            "131",
            "202",
            "124",
            "7",
            "86",
            "113",
            "59",
            "105",
            "37",
            "120",
            "225",
            "128",
            "204",
            "112",
            "49",
            "80",
            "224",
            "99",
            "3",
            "103",
            "217",
            "127",
            "209",
            "162",
            "231",
            "140",
            "223",
            "111",
            "22",
            "70",
            "24",
            "93",
            "15",
            "72",
            "17",
            "116",
            "6",
            "133",
            "181",
            "219",
            "145",
            "16",
            "153",
            "228",
            "123",
            "12",
            "97",
            "236",
            "126",
            "0",
            "130",
            "29",
            "62",
            "33",
            "114",
            "221",
            "167",
            "11",
            "162",
            "129",
            "180",
            "100",
            "1",
            "111",
            "71",
            "21",
            "81",
            "8",
            "109",
            "15",
            "106",
            "28",
            "133",
            "231",
            "173",
            "197",
            "110",
            "43",
            "89",
            "6",
            "176",
            "57",
            "165",
            "189",
            "148",
            "233",
            "144",
            "201",
            "160",
            "211",
            "115",
            "32",
            "157",
            "0",
            "135",
            "221",
            "159",
            "63",
            "17",
            "55",
            "96",
            "13",
            "77",
            "46",
            "20",
            "113",
            "234",
            "109",
            "163",
            "10",
            "108",
            "36",
            "81",
            "207",
            "140",
            "210",
            "117",
            "4",
            "138",
            "237",
            "182",
            "218",
            "123",
            "225",
            "105",
            "47",
            "233",
            "139",
            "235",
            "106",
            "37",
            "137",
            "187",
            "211",
            "110",
            "7",
            "128",
            "3",
            "94",
            "20",
            "83",
            "29",
            "89",
            "62",
            "214",
            "148",
            "205",
            "156",
            "213",
            "125",
            "19",
            "99",
            "66",
            "22",
            "122",
            "49",
            "188",
            "237",
            "131",
            "18",
            "233",
            "118",
            "55",
            "10",
            "138",
            "13",
            "113",
            "64",
            "224",
            "151",
            "177",
            "231",
            "144",
            "220",
            "171",
            "81",
            "105",
            "204",
            "162",
            "58",
            "95",
            "238",
            "100",
            "8",
            "119",
            "1",
            "165",
            "200",
            "146",
            "190",
            "125",
            "217",
            "147",
            "41",
            "90",
            "6",
            "111",
            "25",
            "85",
            "27",
            "70",
            "126",
            "5",
            "91",
            "21",
            "93",
            "227",
            "135",
            "235",
            "143",
            "223",
            "155",
            "206",
            "129",
            "0",
            "94",
            "15",
            "100",
            "215",
            "104",
            "29",
            "173",
            "217",
            "136",
            "209",
            "113",
            "6",
            "121",
            "24",
            "88",
            "8",
            "79",
            "32",
            "57",
            "218",
            "95",
            "226",
            "101",
            "236",
            "134",
            "178",
            "149",
            "193",
            "160",
            "191",
            "237",
            "117",
            "14",
            "49",
            "75",
            "15",
            "132",
            "200",
            "224",
            "127",
            "185",
            "64",
            "10",
            "98",
            "122",
            "221",
            "136",
            "168",
            "239",
            "183",
            "217",
            "109",
            "214",
            "106",
            "17",
            "86",
            "11",
            "120",
            "231",
            "125",
            "12",
            "107",
            "235",
            "121",
            "3",
            "116",
            "159",
            "212",
            "139",
            "229",
            "145",
            "201",
            "129",
            "197",
            "148",
            "216",
            "74",
            "9",
            "65",
            "23",
            "91",
            "40",
            "81",
            "49",
            "90",
            "2",
            "104",
            "41",
            "17",
            "99",
            "20",
            "72",
            "217",
            "146",
            "225",
            "126",
            "207",
            "128",
            "213",
            "185",
            "133",
            "190",
            "64",
            "30",
            "62",
            "115",
            "208",
            "124",
            "226",
            "105",
            "238",
            "125",
            "28",
            "84",
            "7",
            "103",
            "223",
            "130",
            "32",
            "120",
            "228",
            "89",
            "10",
            "155",
            "183",
            "149",
            "188",
            "106",
            "217",
            "97",
            "21",
            "86",
            "3",
            "101",
            "191",
            "142",
            "202",
            "139",
            "236",
            "112",
            "211",
            "163",
            "235",
            "136",
            "17",
            "73",
            "19",
            "105",
            "219",
            "141",
            "205",
            "67",
            "26",
            "92",
            "1",
            "91",
            "60",
            "34",
            "123",
            "220",
            "137",
            "14",
            "121",
            "227",
            "154",
            "221",
            "108",
            "234",
            "117",
            "195",
            "133",
            "238",
            "96",
            "38",
            "114",
            "6",
            "75",
            "2",
            "122",
            "233",
            "141",
            "209",
            "143",
            "202",
            "169",
            "223",
            "134",
            "237",
            "115",
            "35",
            "82",
            "55",
            "24",
            "98",
            "224",
            "139",
            "213",
            "154",
            "15",
            "68",
            "25",
            "131",
            "63",
            "27",
            "120",
            "19",
            "145",
            "48",
            "103",
            "14",
            "76",
            "157",
            "205",
            "161",
            "13",
            "119",
            "232",
            "127",
            "219",
            "170",
            "201",
            "175",
            "149",
            "67",
            "123",
            "209",
            "64",
            "90",
            "7",
            "81",
            "222",
            "158",
            "204",
            "122",
            "214",
            "137",
            "234",
            "116",
            "5",
            "128",
            "203",
            "141",
            "88",
            "11",
            "107",
            "8",
            "99",
            "35",
            "70",
            "112",
            "21",
            "57",
            "238",
            "104",
            "32",
            "117",
            "34",
            "62",
            "224",
            "133",
            "1",
            "143",
            "206",
            "113",
            "7",
            "132",
            "12",
            "84",
            "26",
            "53",
            "111",
            "36",
            "119",
            "20",
            "98",
            "60",
            "215",
            "152",
            "0",
            "102",
            "221",
            "164",
            "192",
            "129",
            "179",
            "220",
            "160",
            "218",
            "146",
            "2",
            "131",
            "228",
            "96",
            "45",
            "106",
            "80",
            "231",
            "165",
            "6",
            "115",
            "43",
            "84",
            "23",
            "107",
            "225",
            "127",
            "17",
            "122",
            "217",
            "134",
            "214",
            "119",
            "21",
            "67",
            "29",
            "79",
            "12",
            "148",
            "207",
            "112",
            "221",
            "121",
            "226",
            "126",
            "19",
            "88",
            "2",
            "93",
            "0",
            "158",
            "236",
            "123",
            "180",
            "219",
            "188",
            "153",
            "196",
            "166",
            "205",
            "135",
            "231",
            "181",
            "211",
            "134",
            "173",
            "141",
            "13",
            "104",
            "54",
            "21",
            "72",
            "96",
            "23",
            "86",
            "12",
            "98",
            "227",
            "105",
            "2",
            "127",
            "42",
            "117",
            "229",
            "56",
            "31",
            "139",
            "8",
            "121",
            "219",
            "142",
            "194",
            "123",
            "6",
            "91",
            "28",
            "119",
            "24",
            "78",
            "35",
            "97",
            "234",
            "93",
            "224",
            "108",
            "7",
            "87",
            "21",
            "115",
            "3",
            "151",
            "200",
            "172",
            "198",
            "147",
            "189",
            "129",
            "237",
            "177",
            "153",
            "226",
            "137",
            "223",
            "101",
            "17",
            "133",
            "4",
            "113",
            "201",
            "146",
            "235",
            "169",
            "2",
            "142",
            "211",
            "139",
            "28",
            "108",
            "215",
            "103",
            "50",
            "76",
            "32",
            "66",
            "183",
            "63",
            "11",
            "111",
            "72",
            "8",
            "105",
            "212",
            "109",
            "39",
            "95",
            "25",
            "70",
            "100",
            "213",
            "237",
            "121",
            "18",
            "132",
            "225",
            "168",
            "144",
            "229",
            "74",
            "15",
            "96",
            "3",
            "83",
            "28",
            "143",
            "5",
            "156",
            "193",
            "125",
            "6",
            "140",
            "217",
            "128",
            "1",
            "164",
            "211",
            "149",
            "201",
            "159",
            "233",
            "104",
            "40",
            "170",
            "65",
            "111",
            "27",
            "174",
            "129",
            "234",
            "103",
            "17",
            "60",
            "35",
            "113",
            "19",
            "95",
            "69",
            "114",
            "3",
            "110",
            "210",
            "157",
            "232",
            "118",
            "227",
            "132",
            "218",
            "121",
            "13",
            "78",
            "107",
            "7",
            "122",
            "211",
            "143",
            "236",
            "91",
            "9",
            "79",
            "28",
            "70",
            "43",
            "169",
            "205",
            "146",
            "219",
            "113",
            "137",
            "216",
            "191",
            "149",
            "233",
            "108",
            "22",
            "72",
            "25",
          ],
          pegs: [
            {
              x: 800,
              y: 400,
              angle: 0,
              index: 0,
              caption: "A-1",
            },
            {
              x: 799.8629299902228,
              y: 410.47077932314926,
              angle: 0.026179938779914945,
              index: 1,
              caption: "A-2",
            },
            {
              x: 799.4518139018295,
              y: 420.93438249717747,
              angle: 0.05235987755982989,
              index: 2,
              caption: "A-3",
            },
            {
              x: 798.7669334932513,
              y: 431.383638291138,
              angle: 0.07853981633974483,
              index: 3,
              caption: "A-4",
            },
            {
              x: 797.8087581473093,
              y: 441.8113853070614,
              angle: 0.10471975511965977,
              index: 4,
              caption: "A-5",
            },
            {
              x: 796.5779445495242,
              y: 452.21047688802065,
              angle: 0.1308996938995747,
              index: 5,
              caption: "A-6",
            },
            {
              x: 795.0753362380551,
              y: 462.57378601609236,
              angle: 0.15707963267948966,
              index: 6,
              caption: "A-7",
            },
            {
              x: 793.3019630255819,
              y: 472.894210196859,
              angle: 0.1832595714594046,
              index: 7,
              caption: "A-8",
            },
            {
              x: 791.2590402935223,
              y: 483.16467632710373,
              angle: 0.20943951023931956,
              index: 8,
              caption: "A-9",
            },
            {
              x: 788.9479681590706,
              y: 493.37814554236223,
              angle: 0.2356194490192345,
              index: 9,
              caption: "A-10",
            },
            {
              x: 786.3703305156273,
              y: 503.5276180410083,
              angle: 0.26179938779914946,
              index: 10,
              caption: "A-11",
            },
            {
              x: 783.5278939472772,
              y: 513.6061378815691,
              angle: 0.2879793265790644,
              index: 11,
              caption: "A-12",
            },
            {
              x: 780.4226065180615,
              y: 523.606797749979,
              angle: 0.3141592653589793,
              index: 12,
              caption: "A-13",
            },
            {
              x: 777.0565964368714,
              y: 533.5227436935083,
              angle: 0.34033920413889424,
              index: 13,
              caption: "A-14",
            },
            {
              x: 773.4321705988807,
              y: 543.3471798181201,
              angle: 0.36651914291880916,
              index: 14,
              caption: "A-15",
            },
            {
              x: 769.5518130045147,
              y: 553.0733729460359,
              angle: 0.3926990816987241,
              index: 15,
              caption: "A-16",
            },
            {
              x: 765.4181830570404,
              y: 562.6946572303201,
              angle: 0.418879020478639,
              index: 16,
              caption: "A-17",
            },
            {
              x: 761.0341137399442,
              y: 572.204438723318,
              angle: 0.44505895925855393,
              index: 17,
              caption: "A-18",
            },
            {
              x: 756.4026096753471,
              y: 581.5961998958187,
              angle: 0.47123889803846886,
              index: 18,
              caption: "A-19",
            },
            {
              x: 751.5268450647862,
              y: 590.8635041038433,
              angle: 0.4974188368183838,
              index: 19,
              caption: "A-20",
            },
            {
              x: 746.4101615137755,
              y: 599.9999999999999,
              angle: 0.5235987755982987,
              index: 20,
              caption: "A-21",
            },
            {
              x: 741.0560657416369,
              y: 608.9994258863795,
              angle: 0.5497787143782137,
              index: 21,
              caption: "A-22",
            },
            {
              x: 735.4682271781696,
              y: 617.8556140060108,
              angle: 0.5759586531581287,
              index: 22,
              caption: "A-23",
            },
            {
              x: 729.6504754488063,
              y: 626.5624947699331,
              angle: 0.6021385919380436,
              index: 23,
              caption: "A-24",
            },
            {
              x: 723.606797749979,
              y: 635.1141009169893,
              angle: 0.6283185307179586,
              index: 24,
              caption: "A-25",
            },
            {
              x: 717.3413361164941,
              y: 643.5045716034882,
              angle: 0.6544984694978736,
              index: 25,
              caption: "A-26",
            },
            {
              x: 710.8583845827884,
              y: 651.728156419935,
              angle: 0.6806784082777886,
              index: 26,
              caption: "A-27",
            },
            {
              x: 704.1623862400124,
              y: 659.7792193320736,
              angle: 0.7068583470577036,
              index: 27,
              caption: "A-28",
            },
            {
              x: 697.2579301909576,
              y: 667.6522425435433,
              angle: 0.7330382858376185,
              index: 28,
              caption: "A-29",
            },
            {
              x: 690.149748404915,
              y: 675.3418302775017,
              angle: 0.7592182246175335,
              index: 29,
              caption: "A-30",
            },
            {
              x: 682.842712474619,
              y: 682.842712474619,
              angle: 0.7853981633974485,
              index: 30,
              caption: "A-31",
            },
            {
              x: 675.3418302775016,
              y: 690.1497484049152,
              angle: 0.8115781021773635,
              index: 31,
              caption: "A-32",
            },
            {
              x: 667.6522425435433,
              y: 697.2579301909578,
              angle: 0.8377580409572785,
              index: 32,
              caption: "A-33",
            },
            {
              x: 659.7792193320734,
              y: 704.1623862400124,
              angle: 0.8639379797371934,
              index: 33,
              caption: "A-34",
            },
            {
              x: 651.7281564199349,
              y: 710.8583845827884,
              angle: 0.8901179185171084,
              index: 34,
              caption: "A-35",
            },
            {
              x: 643.5045716034882,
              y: 717.3413361164942,
              angle: 0.9162978572970234,
              index: 35,
              caption: "A-36",
            },
            {
              x: 635.1141009169892,
              y: 723.606797749979,
              angle: 0.9424777960769384,
              index: 36,
              caption: "A-37",
            },
            {
              x: 626.562494769933,
              y: 729.6504754488063,
              angle: 0.9686577348568534,
              index: 37,
              caption: "A-38",
            },
            {
              x: 617.8556140060106,
              y: 735.4682271781697,
              angle: 0.9948376736367683,
              index: 38,
              caption: "A-39",
            },
            {
              x: 608.9994258863793,
              y: 741.056065741637,
              angle: 1.0210176124166832,
              index: 39,
              caption: "A-40",
            },
            {
              x: 599.9999999999999,
              y: 746.4101615137755,
              angle: 1.047197551196598,
              index: 40,
              caption: "A-41",
            },
            {
              x: 590.8635041038433,
              y: 751.5268450647862,
              angle: 1.073377489976513,
              index: 41,
              caption: "A-42",
            },
            {
              x: 581.5961998958187,
              y: 756.4026096753471,
              angle: 1.0995574287564278,
              index: 42,
              caption: "A-43",
            },
            {
              x: 572.204438723318,
              y: 761.0341137399442,
              angle: 1.1257373675363427,
              index: 43,
              caption: "A-44",
            },
            {
              x: 562.6946572303201,
              y: 765.4181830570403,
              angle: 1.1519173063162575,
              index: 44,
              caption: "A-45",
            },
            {
              x: 553.073372946036,
              y: 769.5518130045147,
              angle: 1.1780972450961724,
              index: 45,
              caption: "A-46",
            },
            {
              x: 543.3471798181201,
              y: 773.4321705988807,
              angle: 1.2042771838760873,
              index: 46,
              caption: "A-47",
            },
            {
              x: 533.5227436935085,
              y: 777.0565964368714,
              angle: 1.2304571226560022,
              index: 47,
              caption: "A-48",
            },
            {
              x: 523.606797749979,
              y: 780.4226065180615,
              angle: 1.256637061435917,
              index: 48,
              caption: "A-49",
            },
            {
              x: 513.6061378815691,
              y: 783.5278939472772,
              angle: 1.282817000215832,
              index: 49,
              caption: "A-50",
            },
            {
              x: 503.52761804100845,
              y: 786.3703305156273,
              angle: 1.3089969389957468,
              index: 50,
              caption: "A-51",
            },
            {
              x: 493.3781455423624,
              y: 788.9479681590706,
              angle: 1.3351768777756616,
              index: 51,
              caption: "A-52",
            },
            {
              x: 483.16467632710396,
              y: 791.2590402935222,
              angle: 1.3613568165555765,
              index: 52,
              caption: "A-53",
            },
            {
              x: 472.89421019685926,
              y: 793.3019630255818,
              angle: 1.3875367553354914,
              index: 53,
              caption: "A-54",
            },
            {
              x: 462.57378601609264,
              y: 795.0753362380551,
              angle: 1.4137166941154062,
              index: 54,
              caption: "A-55",
            },
            {
              x: 452.210476888021,
              y: 796.5779445495241,
              angle: 1.439896632895321,
              index: 55,
              caption: "A-56",
            },
            {
              x: 441.81138530706176,
              y: 797.8087581473093,
              angle: 1.466076571675236,
              index: 56,
              caption: "A-57",
            },
            {
              x: 431.38363829113837,
              y: 798.7669334932511,
              angle: 1.4922565104551508,
              index: 57,
              caption: "A-58",
            },
            {
              x: 420.9343824971779,
              y: 799.4518139018295,
              angle: 1.5184364492350657,
              index: 58,
              caption: "A-59",
            },
            {
              x: 410.47077932314966,
              y: 799.8629299902228,
              angle: 1.5446163880149806,
              index: 59,
              caption: "A-60",
            },
            {
              x: 400.00000000000045,
              y: 800,
              angle: 1.5707963267948954,
              index: 60,
              caption: "B-1",
            },
            {
              x: 389.52922067685125,
              y: 799.8629299902228,
              angle: 1.5969762655748103,
              index: 61,
              caption: "B-2",
            },
            {
              x: 379.065617502823,
              y: 799.4518139018296,
              angle: 1.6231562043547252,
              index: 62,
              caption: "B-3",
            },
            {
              x: 368.61636170886254,
              y: 798.7669334932513,
              angle: 1.64933614313464,
              index: 63,
              caption: "B-4",
            },
            {
              x: 358.1886146929392,
              y: 797.8087581473094,
              angle: 1.675516081914555,
              index: 64,
              caption: "B-5",
            },
            {
              x: 347.78952311198,
              y: 796.5779445495242,
              angle: 1.7016960206944698,
              index: 65,
              caption: "B-6",
            },
            {
              x: 337.42621398390827,
              y: 795.0753362380552,
              angle: 1.7278759594743847,
              index: 66,
              caption: "B-7",
            },
            {
              x: 327.1057898031417,
              y: 793.3019630255819,
              angle: 1.7540558982542995,
              index: 67,
              caption: "B-8",
            },
            {
              x: 316.83532367289695,
              y: 791.2590402935225,
              angle: 1.7802358370342144,
              index: 68,
              caption: "B-9",
            },
            {
              x: 306.62185445763856,
              y: 788.9479681590708,
              angle: 1.8064157758141293,
              index: 69,
              caption: "B-10",
            },
            {
              x: 296.4723819589924,
              y: 786.3703305156274,
              angle: 1.8325957145940441,
              index: 70,
              caption: "B-11",
            },
            {
              x: 286.39386211843174,
              y: 783.5278939472774,
              angle: 1.858775653373959,
              index: 71,
              caption: "B-12",
            },
            {
              x: 276.39320225002183,
              y: 780.4226065180617,
              angle: 1.8849555921538739,
              index: 72,
              caption: "B-13",
            },
            {
              x: 266.47725630649245,
              y: 777.0565964368716,
              angle: 1.9111355309337887,
              index: 73,
              caption: "B-14",
            },
            {
              x: 256.6528201818807,
              y: 773.432170598881,
              angle: 1.9373154697137036,
              index: 74,
              caption: "B-15",
            },
            {
              x: 246.92662705396495,
              y: 769.551813004515,
              angle: 1.9634954084936185,
              index: 75,
              caption: "B-16",
            },
            {
              x: 237.30534276968078,
              y: 765.4181830570408,
              angle: 1.9896753472735333,
              index: 76,
              caption: "B-17",
            },
            {
              x: 227.79556127668283,
              y: 761.0341137399447,
              angle: 2.015855286053448,
              index: 77,
              caption: "B-18",
            },
            {
              x: 218.4038001041821,
              y: 756.4026096753475,
              angle: 2.0420352248333633,
              index: 78,
              caption: "B-19",
            },
            {
              x: 209.13649589615738,
              y: 751.5268450647866,
              angle: 2.0682151636132784,
              index: 79,
              caption: "B-20",
            },
            {
              x: 200.0000000000007,
              y: 746.4101615137758,
              angle: 2.0943951023931935,
              index: 80,
              caption: "B-21",
            },
            {
              x: 191.0005741136211,
              y: 741.0560657416372,
              angle: 2.1205750411731086,
              index: 81,
              caption: "B-22",
            },
            {
              x: 182.14438599398974,
              y: 735.46822717817,
              angle: 2.1467549799530237,
              index: 82,
              caption: "B-23",
            },
            {
              x: 173.43750523006742,
              y: 729.6504754488067,
              angle: 2.1729349187329388,
              index: 83,
              caption: "B-24",
            },
            {
              x: 164.8858990830112,
              y: 723.6067977499794,
              angle: 2.199114857512854,
              index: 84,
              caption: "B-25",
            },
            {
              x: 156.49542839651212,
              y: 717.3413361164943,
              angle: 2.225294796292769,
              index: 85,
              caption: "B-26",
            },
            {
              x: 148.27184358006537,
              y: 710.8583845827886,
              angle: 2.251474735072684,
              index: 86,
              caption: "B-27",
            },
            {
              x: 140.22078066792685,
              y: 704.1623862400126,
              angle: 2.277654673852599,
              index: 87,
              caption: "B-28",
            },
            {
              x: 132.34775745645697,
              y: 697.257930190958,
              angle: 2.303834612632514,
              index: 88,
              caption: "B-29",
            },
            {
              x: 124.65816972249861,
              y: 690.1497484049152,
              angle: 2.3300145514124293,
              index: 89,
              caption: "B-30",
            },
            {
              x: 117.15728752538115,
              y: 682.8427124746191,
              angle: 2.3561944901923444,
              index: 90,
              caption: "B-31",
            },
            {
              x: 109.85025159508504,
              y: 675.3418302775017,
              angle: 2.3823744289722595,
              index: 91,
              caption: "B-32",
            },
            {
              x: 102.74206980904235,
              y: 667.6522425435433,
              angle: 2.4085543677521746,
              index: 92,
              caption: "B-33",
            },
            {
              x: 95.83761375998768,
              y: 659.7792193320736,
              angle: 2.4347343065320897,
              index: 93,
              caption: "B-34",
            },
            {
              x: 89.14161541721164,
              y: 651.7281564199349,
              angle: 2.4609142453120048,
              index: 94,
              caption: "B-35",
            },
            {
              x: 82.6586638835059,
              y: 643.5045716034882,
              angle: 2.48709418409192,
              index: 95,
              caption: "B-36",
            },
            {
              x: 76.39320225002093,
              y: 635.1141009169892,
              angle: 2.513274122871835,
              index: 96,
              caption: "B-37",
            },
            {
              x: 70.34952455119364,
              y: 626.562494769933,
              angle: 2.53945406165175,
              index: 97,
              caption: "B-38",
            },
            {
              x: 64.53177282183024,
              y: 617.8556140060106,
              angle: 2.565634000431665,
              index: 98,
              caption: "B-39",
            },
            {
              x: 58.94393425836295,
              y: 608.9994258863793,
              angle: 2.59181393921158,
              index: 99,
              caption: "B-40",
            },
            {
              x: 53.58983848622434,
              y: 599.9999999999997,
              angle: 2.6179938779914953,
              index: 100,
              caption: "B-41",
            },
            {
              x: 48.47315493521363,
              y: 590.863504103843,
              angle: 2.6441738167714104,
              index: 101,
              caption: "B-42",
            },
            {
              x: 43.59739032465262,
              y: 581.5961998958182,
              angle: 2.6703537555513255,
              index: 102,
              caption: "B-43",
            },
            {
              x: 38.96588626005553,
              y: 572.2044387233176,
              angle: 2.6965336943312406,
              index: 103,
              caption: "B-44",
            },
            {
              x: 34.581816942959385,
              y: 562.6946572303195,
              angle: 2.7227136331111557,
              index: 104,
              caption: "B-45",
            },
            {
              x: 30.448186995485038,
              y: 553.0733729460353,
              angle: 2.7488935718910708,
              index: 105,
              caption: "B-46",
            },
            {
              x: 26.567829401119035,
              y: 543.3471798181195,
              angle: 2.775073510670986,
              index: 106,
              caption: "B-47",
            },
            {
              x: 22.943403563128363,
              y: 533.5227436935077,
              angle: 2.801253449450901,
              index: 107,
              caption: "B-48",
            },
            {
              x: 19.577393481938323,
              y: 523.6067977499782,
              angle: 2.827433388230816,
              index: 108,
              caption: "B-49",
            },
            {
              x: 16.472106052722513,
              y: 513.6061378815682,
              angle: 2.853613327010731,
              index: 109,
              caption: "B-50",
            },
            {
              x: 13.629669484372453,
              y: 503.52761804100743,
              angle: 2.879793265790646,
              index: 110,
              caption: "B-51",
            },
            {
              x: 11.05203184092911,
              y: 493.37814554236115,
              angle: 2.9059732045705613,
              index: 111,
              caption: "B-52",
            },
            {
              x: 8.740959706477502,
              y: 483.1646763271027,
              angle: 2.9321531433504764,
              index: 112,
              caption: "B-53",
            },
            {
              x: 6.698036974417976,
              y: 472.89421019685784,
              angle: 2.9583330821303915,
              index: 113,
              caption: "B-54",
            },
            {
              x: 4.924663761944714,
              y: 462.57378601609116,
              angle: 2.9845130209103066,
              index: 114,
              caption: "B-55",
            },
            {
              x: 3.4220554504756695,
              y: 452.2104768880194,
              angle: 3.0106929596902217,
              index: 115,
              caption: "B-56",
            },
            {
              x: 2.1912418526905064,
              y: 441.81138530706005,
              angle: 3.0368728984701368,
              index: 116,
              caption: "B-57",
            },
            {
              x: 1.2330665067486812,
              y: 431.3836382911366,
              angle: 3.063052837250052,
              index: 117,
              caption: "B-58",
            },
            {
              x: 0.5481860981703779,
              y: 420.9343824971761,
              angle: 3.089232776029967,
              index: 118,
              caption: "B-59",
            },
            {
              x: 0.13707000977705164,
              y: 410.4707793231477,
              angle: 3.115412714809882,
              index: 119,
              caption: "B-60",
            },
            {
              x: 0,
              y: 399.99999999999847,
              angle: 3.141592653589797,
              index: 120,
              caption: "C-1",
            },
            {
              x: 0.13707000977714046,
              y: 389.5292206768491,
              angle: 3.167772592369712,
              index: 121,
              caption: "C-2",
            },
            {
              x: 0.5481860981705555,
              y: 379.0656175028208,
              angle: 3.1939525311496273,
              index: 122,
              caption: "C-3",
            },
            {
              x: 1.2330665067489477,
              y: 368.61636170886027,
              angle: 3.2201324699295424,
              index: 123,
              caption: "C-4",
            },
            {
              x: 2.1912418526908617,
              y: 358.1886146929368,
              angle: 3.2463124087094575,
              index: 124,
              caption: "C-5",
            },
            {
              x: 3.422055450476069,
              y: 347.78952311197753,
              angle: 3.2724923474893726,
              index: 125,
              caption: "C-6",
            },
            {
              x: 4.924663761945203,
              y: 337.42621398390577,
              angle: 3.2986722862692877,
              index: 126,
              caption: "C-7",
            },
            {
              x: 6.698036974418509,
              y: 327.1057898031391,
              angle: 3.3248522250492027,
              index: 127,
              caption: "C-8",
            },
            {
              x: 8.740959706478169,
              y: 316.8353236728943,
              angle: 3.351032163829118,
              index: 128,
              caption: "C-9",
            },
            {
              x: 11.052031840929821,
              y: 306.62185445763583,
              angle: 3.377212102609033,
              index: 129,
              caption: "C-10",
            },
            {
              x: 13.629669484373252,
              y: 296.4723819589897,
              angle: 3.403392041388948,
              index: 130,
              caption: "C-11",
            },
            {
              x: 16.4721060527234,
              y: 286.39386211842884,
              angle: 3.429571980168863,
              index: 131,
              caption: "C-12",
            },
            {
              x: 19.577393481939254,
              y: 276.3932022500189,
              angle: 3.455751918948778,
              index: 132,
              caption: "C-13",
            },
            {
              x: 22.94340356312943,
              y: 266.47725630648944,
              angle: 3.4819318577286933,
              index: 133,
              caption: "C-14",
            },
            {
              x: 26.567829401120147,
              y: 256.6528201818777,
              angle: 3.5081117965086084,
              index: 134,
              caption: "C-15",
            },
            {
              x: 30.44818699548624,
              y: 246.92662705396185,
              angle: 3.5342917352885235,
              index: 135,
              caption: "C-16",
            },
            {
              x: 34.58181694296067,
              y: 237.30534276967762,
              angle: 3.5604716740684386,
              index: 136,
              caption: "C-17",
            },
            {
              x: 38.96588626005686,
              y: 227.79556127667965,
              angle: 3.5866516128483537,
              index: 137,
              caption: "C-18",
            },
            {
              x: 43.59739032465404,
              y: 218.403800104179,
              angle: 3.6128315516282687,
              index: 138,
              caption: "C-19",
            },
            {
              x: 48.473154935215135,
              y: 209.13649589615426,
              angle: 3.639011490408184,
              index: 139,
              caption: "C-20",
            },
            {
              x: 53.589838486225894,
              y: 199.99999999999764,
              angle: 3.665191429188099,
              index: 140,
              caption: "C-21",
            },
            {
              x: 58.94393425836455,
              y: 191.0005741136181,
              angle: 3.691371367968014,
              index: 141,
              caption: "C-22",
            },
            {
              x: 64.53177282183194,
              y: 182.14438599398676,
              angle: 3.717551306747929,
              index: 142,
              caption: "C-23",
            },
            {
              x: 70.34952455119536,
              y: 173.43750523006446,
              angle: 3.743731245527844,
              index: 143,
              caption: "C-24",
            },
            {
              x: 76.3932022500228,
              y: 164.88589908300835,
              angle: 3.7699111843077593,
              index: 144,
              caption: "C-25",
            },
            {
              x: 82.6586638835078,
              y: 156.49542839650934,
              angle: 3.7960911230876744,
              index: 145,
              caption: "C-26",
            },
            {
              x: 89.14161541721359,
              y: 148.2718435800626,
              angle: 3.8222710618675895,
              index: 146,
              caption: "C-27",
            },
            {
              x: 95.83761375998967,
              y: 140.22078066792415,
              angle: 3.8484510006475046,
              index: 147,
              caption: "C-28",
            },
            {
              x: 102.74206980904444,
              y: 132.34775745645436,
              angle: 3.8746309394274197,
              index: 148,
              caption: "C-29",
            },
            {
              x: 109.85025159508717,
              y: 124.65816972249604,
              angle: 3.9008108782073347,
              index: 149,
              caption: "C-30",
            },
            {
              x: 117.15728752538332,
              y: 117.15728752537866,
              angle: 3.92699081698725,
              index: 150,
              caption: "C-31",
            },
            {
              x: 124.65816972250083,
              y: 109.85025159508264,
              angle: 3.953170755767165,
              index: 151,
              caption: "C-32",
            },
            {
              x: 132.34775745645928,
              y: 102.74206980903999,
              angle: 3.97935069454708,
              index: 152,
              caption: "C-33",
            },
            {
              x: 140.22078066792906,
              y: 95.83761375998546,
              angle: 4.005530633326995,
              index: 153,
              caption: "C-34",
            },
            {
              x: 148.2718435800676,
              y: 89.14161541720951,
              angle: 4.03171057210691,
              index: 154,
              caption: "C-35",
            },
            {
              x: 156.49542839651446,
              y: 82.65866388350385,
              angle: 4.057890510886825,
              index: 155,
              caption: "C-36",
            },
            {
              x: 164.88589908301358,
              y: 76.39320225001897,
              angle: 4.08407044966674,
              index: 156,
              caption: "C-37",
            },
            {
              x: 173.4375052300698,
              y: 70.34952455119172,
              angle: 4.110250388446655,
              index: 157,
              caption: "C-38",
            },
            {
              x: 182.1443859939922,
              y: 64.53177282182843,
              angle: 4.13643032722657,
              index: 158,
              caption: "C-39",
            },
            {
              x: 191.0005741136236,
              y: 58.943934258361175,
              angle: 4.162610266006485,
              index: 159,
              caption: "C-40",
            },
            {
              x: 200.00000000000318,
              y: 53.589838486222696,
              angle: 4.1887902047864,
              index: 160,
              caption: "C-41",
            },
            {
              x: 209.13649589615994,
              y: 48.47315493521202,
              angle: 4.214970143566315,
              index: 161,
              caption: "C-42",
            },
            {
              x: 218.40380010418468,
              y: 43.59739032465111,
              angle: 4.2411500823462305,
              index: 162,
              caption: "C-43",
            },
            {
              x: 227.79556127668545,
              y: 38.96588626005406,
              angle: 4.267330021126146,
              index: 163,
              caption: "C-44",
            },
            {
              x: 237.30534276968353,
              y: 34.581816942958014,
              angle: 4.293509959906061,
              index: 164,
              caption: "C-45",
            },
            {
              x: 246.9266270539678,
              y: 30.448186995483752,
              angle: 4.319689898685976,
              index: 165,
              caption: "C-46",
            },
            {
              x: 256.6528201818837,
              y: 26.567829401117837,
              angle: 4.345869837465891,
              index: 166,
              caption: "C-47",
            },
            {
              x: 266.4772563064955,
              y: 22.94340356312725,
              angle: 4.372049776245806,
              index: 167,
              caption: "C-48",
            },
            {
              x: 276.393202250025,
              y: 19.577393481937257,
              angle: 4.398229715025721,
              index: 168,
              caption: "C-49",
            },
            {
              x: 286.39386211843504,
              y: 16.47210605272158,
              angle: 4.424409653805636,
              index: 169,
              caption: "C-50",
            },
            {
              x: 296.47238195899587,
              y: 13.629669484371565,
              angle: 4.450589592585551,
              index: 170,
              caption: "C-51",
            },
            {
              x: 306.6218544576421,
              y: 11.052031840928356,
              angle: 4.476769531365466,
              index: 171,
              caption: "C-52",
            },
            {
              x: 316.8353236729006,
              y: 8.740959706476836,
              angle: 4.502949470145381,
              index: 172,
              caption: "C-53",
            },
            {
              x: 327.10578980314546,
              y: 6.698036974417354,
              angle: 4.5291294089252965,
              index: 173,
              caption: "C-54",
            },
            {
              x: 337.42621398391213,
              y: 4.924663761944181,
              angle: 4.555309347705212,
              index: 174,
              caption: "C-55",
            },
            {
              x: 347.78952311198395,
              y: 3.4220554504752254,
              angle: 4.581489286485127,
              index: 175,
              caption: "C-56",
            },
            {
              x: 358.18861469294325,
              y: 2.1912418526901956,
              angle: 4.607669225265042,
              index: 176,
              caption: "C-57",
            },
            {
              x: 368.61636170886675,
              y: 1.2330665067484592,
              angle: 4.633849164044957,
              index: 177,
              caption: "C-58",
            },
            {
              x: 379.06561750282725,
              y: 0.5481860981702003,
              angle: 4.660029102824872,
              index: 178,
              caption: "C-59",
            },
            {
              x: 389.5292206768556,
              y: 0.13707000977696282,
              angle: 4.686209041604787,
              index: 179,
              caption: "C-60",
            },
            {
              x: 400.0000000000049,
              y: 0,
              angle: 4.712388980384702,
              index: 180,
              caption: "D-1",
            },
            {
              x: 410.4707793231542,
              y: 0.13707000977722927,
              angle: 4.738568919164617,
              index: 181,
              caption: "D-2",
            },
            {
              x: 420.9343824971826,
              y: 0.5481860981707332,
              angle: 4.764748857944532,
              index: 182,
              caption: "D-3",
            },
            {
              x: 431.3836382911431,
              y: 1.2330665067492141,
              angle: 4.790928796724447,
              index: 183,
              caption: "D-4",
            },
            {
              x: 441.8113853070665,
              y: 2.191241852691217,
              angle: 4.8171087355043625,
              index: 184,
              caption: "D-5",
            },
            {
              x: 452.21047688802577,
              y: 3.4220554504765133,
              angle: 4.843288674284278,
              index: 185,
              caption: "D-6",
            },
            {
              x: 462.57378601609753,
              y: 4.924663761945736,
              angle: 4.869468613064193,
              index: 186,
              caption: "D-7",
            },
            {
              x: 472.89421019686426,
              y: 6.69803697441913,
              angle: 4.895648551844108,
              index: 187,
              caption: "D-8",
            },
            {
              x: 483.164676327109,
              y: 8.740959706478879,
              angle: 4.921828490624023,
              index: 188,
              caption: "D-9",
            },
            {
              x: 493.37814554236746,
              y: 11.05203184093062,
              angle: 4.948008429403938,
              index: 189,
              caption: "D-10",
            },
            {
              x: 503.5276180410136,
              y: 13.629669484374096,
              angle: 4.974188368183853,
              index: 190,
              caption: "D-11",
            },
            {
              x: 513.6061378815743,
              y: 16.472106052724378,
              angle: 5.000368306963768,
              index: 191,
              caption: "D-12",
            },
            {
              x: 523.6067977499843,
              y: 19.57739348194032,
              angle: 5.026548245743683,
              index: 192,
              caption: "D-13",
            },
            {
              x: 533.5227436935137,
              y: 22.943403563130538,
              angle: 5.052728184523598,
              index: 193,
              caption: "D-14",
            },
            {
              x: 543.3471798181255,
              y: 26.567829401121344,
              angle: 5.078908123303513,
              index: 194,
              caption: "D-15",
            },
            {
              x: 553.0733729460412,
              y: 30.448186995487525,
              angle: 5.1050880620834285,
              index: 195,
              caption: "D-16",
            },
            {
              x: 562.6946572303254,
              y: 34.58181694296201,
              angle: 5.131268000863344,
              index: 196,
              caption: "D-17",
            },
            {
              x: 572.2044387233234,
              y: 38.965886260058284,
              angle: 5.157447939643259,
              index: 197,
              caption: "D-18",
            },
            {
              x: 581.596199895824,
              y: 43.59739032465555,
              angle: 5.183627878423174,
              index: 198,
              caption: "D-19",
            },
            {
              x: 590.8635041038486,
              y: 48.47315493521673,
              angle: 5.209807817203089,
              index: 199,
              caption: "D-20",
            },
            {
              x: 600.0000000000052,
              y: 53.58983848622758,
              angle: 5.235987755983004,
              index: 200,
              caption: "D-21",
            },
            {
              x: 608.9994258863848,
              y: 58.943934258366326,
              angle: 5.262167694762919,
              index: 201,
              caption: "D-22",
            },
            {
              x: 617.855614006016,
              y: 64.53177282183375,
              angle: 5.288347633542834,
              index: 202,
              caption: "D-23",
            },
            {
              x: 626.5624947699383,
              y: 70.34952455119728,
              angle: 5.314527572322749,
              index: 203,
              caption: "D-24",
            },
            {
              x: 635.1141009169943,
              y: 76.39320225002476,
              angle: 5.340707511102664,
              index: 204,
              caption: "D-25",
            },
            {
              x: 643.5045716034933,
              y: 82.6586638835098,
              angle: 5.366887449882579,
              index: 205,
              caption: "D-26",
            },
            {
              x: 651.72815641994,
              y: 89.14161541721568,
              angle: 5.3930673886624945,
              index: 206,
              caption: "D-27",
            },
            {
              x: 659.7792193320784,
              y: 95.83761375999185,
              angle: 5.41924732744241,
              index: 207,
              caption: "D-28",
            },
            {
              x: 667.6522425435481,
              y: 102.7420698090467,
              angle: 5.445427266222325,
              index: 208,
              caption: "D-29",
            },
            {
              x: 675.3418302775063,
              y: 109.85025159508947,
              angle: 5.47160720500224,
              index: 209,
              caption: "D-30",
            },
            {
              x: 682.8427124746238,
              y: 117.15728752538568,
              angle: 5.497787143782155,
              index: 210,
              caption: "D-31",
            },
            {
              x: 690.1497484049196,
              y: 124.65816972250327,
              angle: 5.52396708256207,
              index: 211,
              caption: "D-32",
            },
            {
              x: 697.2579301909623,
              y: 132.34775745646178,
              angle: 5.550147021341985,
              index: 212,
              caption: "D-33",
            },
            {
              x: 704.1623862400169,
              y: 140.22078066793173,
              angle: 5.5763269601219,
              index: 213,
              caption: "D-34",
            },
            {
              x: 710.8583845827927,
              y: 148.27184358007037,
              angle: 5.602506898901815,
              index: 214,
              caption: "D-35",
            },
            {
              x: 717.3413361164983,
              y: 156.4954283965173,
              angle: 5.62868683768173,
              index: 215,
              caption: "D-36",
            },
            {
              x: 723.6067977499831,
              y: 164.88589908301643,
              angle: 5.654866776461645,
              index: 216,
              caption: "D-37",
            },
            {
              x: 729.6504754488103,
              y: 173.43750523007267,
              angle: 5.6810467152415605,
              index: 217,
              caption: "D-38",
            },
            {
              x: 735.4682271781735,
              y: 182.14438599399517,
              angle: 5.7072266540214756,
              index: 218,
              caption: "D-39",
            },
            {
              x: 741.0560657416406,
              y: 191.0005741136266,
              angle: 5.733406592801391,
              index: 219,
              caption: "D-40",
            },
            {
              x: 746.410161513779,
              y: 200.0000000000063,
              angle: 5.759586531581306,
              index: 220,
              caption: "D-41",
            },
            {
              x: 751.5268450647897,
              y: 209.13649589616307,
              angle: 5.785766470361221,
              index: 221,
              caption: "D-42",
            },
            {
              x: 756.4026096753505,
              y: 218.40380010418787,
              angle: 5.811946409141136,
              index: 222,
              caption: "D-43",
            },
            {
              x: 761.0341137399475,
              y: 227.79556127668866,
              angle: 5.838126347921051,
              index: 223,
              caption: "D-44",
            },
            {
              x: 765.4181830570434,
              y: 237.30534276968677,
              angle: 5.864306286700966,
              index: 224,
              caption: "D-45",
            },
            {
              x: 769.5518130045176,
              y: 246.9266270539711,
              angle: 5.890486225480881,
              index: 225,
              caption: "D-46",
            },
            {
              x: 773.4321705988834,
              y: 256.652820181887,
              angle: 5.916666164260796,
              index: 226,
              caption: "D-47",
            },
            {
              x: 777.0565964368739,
              y: 266.4772563064989,
              angle: 5.942846103040711,
              index: 227,
              caption: "D-48",
            },
            {
              x: 780.4226065180637,
              y: 276.39320225002837,
              angle: 5.9690260418206265,
              index: 228,
              caption: "D-49",
            },
            {
              x: 783.5278939472795,
              y: 286.3938621184384,
              angle: 5.9952059806005416,
              index: 229,
              caption: "D-50",
            },
            {
              x: 786.3703305156293,
              y: 296.4723819589993,
              angle: 6.021385919380457,
              index: 230,
              caption: "D-51",
            },
            {
              x: 788.9479681590725,
              y: 306.6218544576455,
              angle: 6.047565858160372,
              index: 231,
              caption: "D-52",
            },
            {
              x: 791.2590402935239,
              y: 316.83532367290405,
              angle: 6.073745796940287,
              index: 232,
              caption: "D-53",
            },
            {
              x: 793.3019630255833,
              y: 327.1057898031489,
              angle: 6.099925735720202,
              index: 233,
              caption: "D-54",
            },
            {
              x: 795.0753362380564,
              y: 337.4262139839156,
              angle: 6.126105674500117,
              index: 234,
              caption: "D-55",
            },
            {
              x: 796.5779445495252,
              y: 347.7895231119875,
              angle: 6.152285613280032,
              index: 235,
              caption: "D-56",
            },
            {
              x: 797.8087581473102,
              y: 358.18861469294677,
              angle: 6.178465552059947,
              index: 236,
              caption: "D-57",
            },
            {
              x: 798.7669334932518,
              y: 368.6163617088703,
              angle: 6.204645490839862,
              index: 237,
              caption: "D-58",
            },
            {
              x: 799.4518139018301,
              y: 379.0656175028308,
              angle: 6.230825429619777,
              index: 238,
              caption: "D-59",
            },
            {
              x: 799.8629299902232,
              y: 389.5292206768591,
              angle: 6.2570053683996925,
              index: 239,
              caption: "D-60",
            },
          ],
          createdAt: "2023-05-06T18:32:27.932Z",
          updatedAt: "2023-05-06T18:32:27.932Z",
          deletedAt: null,
        },
      ],
    },
  },
  getters: {
    currentLang: (state) => state.currentLang,
    currentCode: (state) => state.currentCode,
    testCode: (state) => state.testCode,
  },
  mutations: {
    set(state, { type, items }) {
      state[type] = items;
    },
  },
  actions: {
    setLang({ commit }, lang) {
      commit("set", {
        type: "currentLang",
        items: lang,
      });
    },
    async getVerifiedCodeInfo({ commit }, params) {
      await getVerifiedCodeInfo(params).then((response) => {
        localStorage.setItem(
          "currentCode",
          JSON.stringify(response.data.codeValue)
        );
        if (response.data.isSelected) {
          localStorage.setItem(
            "isSelected",
            JSON.stringify(response.data.isSelected)
          );
        } else {
          // localStorage.removeItem("isSelected");
        }
        commit("set", { type: "currentCode", items: response.data });
      });
    },
  },
};
